<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-linkbtn"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-linkbtn">
    <button
        type="button"
        class="btn-link whitespace-no-wrap"
        @click="handleClick"
    >
      {{text}}
    </button>
  </td>
</template>

<script>

import VuetableFieldMixin from "vue3-vuetable/src/components/VuetableFieldMixin";

export default {
  name: 'LinkButtonField',
  mixins: [VuetableFieldMixin],
  computed: {
    text() {
      const text = this.rowField.switch?.text;
      return typeof text === 'function' ? text(this.rowData) : text;
    },
  },
  methods: {
    handleClick() {
      const onClick = this.rowField.switch?.onClick;
      if (typeof onClick === 'function') {
        onClick({ data: this.rowData, index: this.rowIndex });
      }
    },
  },
}
</script>

<style scoped>

</style>
