<template>
  <div>
    <editable-list
      ref="list"
      title="meta roles"
      titleClass="form-section-title"
      :data="metaRoles"
      :fields="fields"
      track-by="name"
      @remove-row="remove"
    >
      <template v-slot:empty-result>
        <Icon name="info" class="h-4 w-4 inline mr-1" />
        Please add at least one metarole to this bundle.
      </template>
      <template v-slot:row-template="">
        <Form :submit="add">
          <template v-slot="props">
            <div class="row-template">
              <div class="form-row">
                <div class="field-name">
                  <TextField
                    name="name"
                    placeholder="new meta role"
                    :validate="required"
                    clearable />
                </div>
                <div class="flex-1">
                  <div class="field-description">
                    <TextField
                      name="description"
                      placeholder="give a description"
                      :validate="required"
                      clearable />
                  </div>
                </div>
                <row-template-actions class="form-row justify-end pl-2" @cancel="cancel" :disabled="props.invalid" />
              </div>
            </div>
          </template>
        </Form>
      </template>
    </editable-list>
  </div>
</template>

<script>
  import {cloneDeep} from 'lodash-es';
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import Icon from '@/components/ui/Icon';
  import Form from "@/components/form/Form";
  import TextField from '@/components/form/TextField';
  import EditableList from "@/components/ui/inlineediting/EditableList";
  import RowTemplateActions from '@/components/ui/inlineediting/RowTemplateActions';
  import TextEditableField from "@/components/auth/list/fields/TextEditableField";
  import LinkButtonField from "@/components/auth/list/fields/LinkButtonField";
  import {v4} from "uuid";

  export default {
    name: 'BundleMetaRoles',
    components: {
      Icon,
      Form,
      TextField,
      EditableList,
      RowTemplateActions,
    },
    mixins: [
      ConfirmationMixin,
      NotifyMixin,
      ValidatorMixin,
    ],
    props: {
      data:{
        type: Array,
        default() {
          return [];
        }
      },
    },
    emits: ['update'],
    data() {
      return {
        metaRoles: [],
        fields: [
          {
            name: TextEditableField,
            title: 'role name',
            titleClass: 'relative pr-2',
            dataClass: 'pr-2',
            width: '28%',
            switch: {
              fieldName: 'name',
              getValidator: () => this.required,
            }
          },
          {
            name: TextEditableField,
            title: 'role description',
            titleClass: 'relative px-2',
            dataClass: 'px-2',
            width: '34%',
            switch: {
              fieldName: 'description',
              getValidator: () => this.required,
            }
          },
          {
            name: LinkButtonField,
            title: '',
            titleClass: 'px-2',
            dataClass: 'px-2',
            width: '18%',
            switch: {
              text: ({ id }) => id.includes('fake') ?  '' : 'view usage',
              onClick: this.goToMetaroleUsage,
            }
          },
          {
            name: 'inline-actions',
            title: '',
            titleClass: 'relative px-2',
            dataClass: 'px-2',
            width: '10%',
          }
        ],
      };
    },
    computed: {
      metaRolesKeys() {
        return this.metaRoles.map(({ name }) => name);
      },
      bundleId() {
        return this.$route.params.id;
      },
    },
    watch: {
      data() {
        this.metaRoles = cloneDeep(this.data);
      },
      metaRoles:  {
        handler: function () {
          this.$emit('update', this.metaRoles);
        },
        deep: true,
      }
    },
    methods: {
      add(values){
        if (this.metaRolesKeys.includes(values.name)) {
          this.notifyError(`The metarole with name ${values.name} has been already added`);
        } else {
          this.metaRoles.push({ ...values, id: `fake-${v4()}`});
          this.$refs.list.hideNewRow();
        }
      },
      cancel() {
        this.$refs.list.hideNewRow();
      },
      remove(index, { id, name } = {}) {
        this.requestConfirmation({
          confirmationMessage: 'are you sure you want to delete this metarole?',
          confirmBtnText: 'yes',
          cancelBtnText: 'no',
          confirmationType: 'warning'
        })
          .then(confirmed => {
            if (!confirmed) {
              return null;
            }

            if (this.bundleId) {
              this.$authDataProvider.getList('bundleUsage', { bundleId: this.bundleId })
                  .then(({content}) => {
                    const metaRoleToRemove = content.find(item => item.metaRoleId === id);
                    if (metaRoleToRemove?.usages > 0) {
                      const usageText = metaRoleToRemove.usages > 1 ? `${metaRoleToRemove.usages} usages` : '1 usage';
                      this.notifyError(`Metarole '${name}' has ${usageText} and can not be removed`);
                    } else {
                      this.metaRoles.splice(index,1);
                    }
                  })
                  .catch((err) => this.notifyError(err.message));
            } else {
              // edit metaroles while creating a new bundle
              this.metaRoles.splice(index,1);
            }
          });
      },
      goToMetaroleUsage({ data }) {
        this.$router.push({
          name: 'applications.bundles.metarole.index',
          params: {
            bundleId: this.bundleId,
            metaroleId: data.id,
          },
        });
      },
    },
  };
</script>
<style scoped>
  .row-template :deep(.form-col) {
    @apply mx-0;
  }
  .row-template :deep(.field-name) {
    @apply pl-4 pr-2;
    width: 32%;
  }
  .row-template :deep(.field-description) {
    @apply px-2;
  }
</style>
