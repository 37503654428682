<template>
  <div class="mb-8">
    <div class="flex items-center">
      <span class="flex-1 form-section-title">applications mapping</span>
      <button
        class="btn-action"
        type="button"
        :disabled="isNewRowVisible"
        @click.stop="isNewRowVisible = true"
      >
        <icon class="w-4 h-4 mr-1" name="plus"/>add application
      </button>
    </div>

    <div>
      <div class="flex text-xs py-3 border-b border-black">
        <div class="col-app">application</div>
        <div class="col-roles flex items-center">
          <div class="col-metarole">metarole</div>
          <div class="col-approle">app role</div>
        </div>
      </div>

      <div v-if="isNewRowVisible" class="form-col row-template px-4 py-8">
        <div v-if="metaroles.length === 0" class="text-blue-800 pb-4 text-sm">
          Add at least one metarole to the bundle before including any application.
        </div>
        <label class="label">select application</label>
        <div class="flex justify-between">
          <div class="flex items-center">
            <dropdown
              placeholder="select an application"
              :options="availableTargetApps"
              :value="selectedAppId"
              @input="selectApp"
            ></dropdown>
            <button
              class="btn-primary btn-solid btn-small mx-4"
              type="button"
              :disabled="!selectedAppId || metaroles.length === 0"
              @click.stop="includeApp"
            >
              add
            </button>
          </div>
          <button class="flex items-center btn-action-warning focus:outline-none" type="button" @click.stop="hideNewRow">
            <icon class="w-4 h-4 mr-1" name="close"/>cancel
          </button>
        </div>
      </div>

      <InfoBlock v-if="bundleApps.length === 0" class="text-sm text-center p-4">no data available</InfoBlock>

      <div class="text-sm">
        <div v-for="(app, index) in bundleApps" :key="app.id">
          <div class="flex justify-between px-4 py-3 bg-gray-100 app-title">
            <div class="text-black font-500 font-inter">{{app.name}}</div>
            <button
              class="flex items-center btn-action-warning focus:outline-none opacity-0"
              type="button"
              @click.stop="() => excludeApp(index, app)"
            >
              <icon class="w-4 h-4 mr-1" name="close"/>exclude
            </button>
          </div>
          <div class="pt-2 pb-6 flex">
            <div v-if="!allRolesMapped[app.id]" class="text-blue-800 p-4 text-sm">
              Please match all meta roles with application roles. You may map one application role to several meta roles.
            </div>
            <div class="col-roles">
              <div v-for="(metarole) in metaroles" :key="`${app.id}-${metarole.id}`" class="flex items-center py-2">
                <div class="col-metarole">{{metarole.name}}</div>
                <div class="col-approle">
                  <SelectInput
                    :name="`mappedRoles[${metarole.id}][${app.id}]`"
                    :options="app.appRoles"
                    :validate="required"
                    no-clear
                  >
                    <template v-slot:option="{ option, selected }">
                      <div :title="option.description" class="flex w-full items-center">
                        <div class="flex flex-shrink-0 justify-center items-center w-3 h-3 mr-2">
                          <Icon v-if="selected" name="checkmark" class="w-3 h-3"/>
                        </div>
                        <span >{{option.value}}</span>
                      </div>
                    </template>
                  </SelectInput>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {sortBy} from 'lodash-es';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import Icon from "@/components/ui/Icon";
  import Dropdown from "@/components/ui/Dropdown";
  import SelectInput from "@/components/form/SelectInput";
  import InfoBlock from "@/components/auth/InfoBlock";
  import { ROLES } from '@/components/auth/constants';

  export default {
    name: 'BundleApplicationRoles',
    components: {
      InfoBlock,
      Dropdown,
      Icon,
      SelectInput,
    },
    mixins: [
      ValidatorMixin,
      NotifyMixin,
      ModalNavigation
    ],
    props: {
      initialApps: {
        type: Array,
        default() {
          return [];
        }
      },
      initialMappedRoles: {
        type: Object,
        default() {
          return {};
        }
      },
      metaroles: {
        type: Array,
        default() {
          return [];
        }
      },
      mappedRoles: {
        type: Object,
        default() {
          return {};
        }
      },
    },
    emits: ['update-apps'],
    data() {
      return {
        isNewRowVisible: false,
        apps: [], // apps, not included in any bundle yet
        includedApps: [],
        roles: [],
        selectedAppId: null,
      };
    },
    computed: {
      availableTargetApps() {
        const includedAppIds = this.includedApps.map(item => item.id);
        const initApps = this.initialApps.map(a => ({ id: a.appId, name: a.appName }));
        return this.apps
            .concat(initApps)
            .filter(item => !includedAppIds.includes(item.id))
            .map(({name: value, id: key}) => ({key, value}));
      },
      appRoles() {
        return this.roles.reduce((acc, item) => {
          acc[item.appId] = item.roleOptions;
          return acc;
        }, {})
      },
      bundleApps() {
        return this.includedApps.map((item) => ({ ...item, appRoles: this.appRoles[item.id] || []}));
      },
      initAppRoles() {
        return Object.values(this.initialMappedRoles).reduce((acc, mappedRole) => {
          Object.entries(mappedRole).forEach(([appId, roleId]) => {
            if (acc[appId]) {
              acc[appId].push(roleId);
            } else {
              acc[appId] = [roleId];
            }
          });
          return acc;
        }, {});
      },
      allRolesMapped() {
        return this.includedApps.reduce((acc, { id: appId }) => {
          acc[appId] = this.metaroles
              .map(({ id: metaroleId }) => this.mappedRoles[metaroleId]?.[appId])
              .every(Boolean);
          return acc;
        }, {});
      },
    },
    watch: {
      initialApps: {
        handler: function () {
          this.includedApps = this.initialApps.map(a => ({ id: a.appId, name: a.appName }));

          Promise.all([
            ...this.initialApps.map(({appId}) => this.fetchAppRoles(appId)),
          ]);
        },
        deep: true,
        immediate: true
      },
      includedApps: {
        handler: function () {
          this.$emit('update-apps', this.includedApps);
        },
        deep: true,
        immediate: true
      },
    },
    methods: {
      hideNewRow() {
        this.isNewRowVisible = false;
        this.selectedAppId = null;
      },

      selectApp(value) {
        this.selectedAppId = value;
      },

      includeApp() {
        const app = this.apps.find(({id}) => id === this.selectedAppId);
        this.includedApps.unshift(app);
        this.hideNewRow();
      },

      excludeApp(index) {
        this.includedApps.splice(index, 1);
      },

      fetchAppRoles(appId) {
        this.$authDataProvider.getList('appRoles', { appId, size: 999 })
          .then(({ content }) => {
            const appRolesInUse = this.initAppRoles[appId] || [];
            const options = sortBy(content, 'deletedAt')
                .filter(({ appRoleId, deletedAt, }) => !deletedAt || appRolesInUse.includes(appRoleId))
                .map(({ appRoleId, alias, deletedAt, description }) => ({
                  key: appRoleId,
                  value: `${deletedAt ? 'DELETED:' : ''}${alias}`,
                  $isDisabled: Boolean(deletedAt),
                  description,
                }));
            const roleOptions = [{ key: ROLES.NO_ROLE, value: 'No role' }].concat(options);
            this.roles.push({ appId, roleOptions});
          })
          .catch((err) => this.notifyError(err.message));
      },

      fetchApps() {
        this.$authDataProvider.getList('apps', {
          size: 999,
          type: 'STAND_ALONE',
          statuses: ['ACTIVE', 'ERROR'],
          nonDeleted: true,
        })
          .then(({content}) => {
            this.apps = content.filter(app => !app.isCustomerUserAgnostic);

            Promise.all([
              ...this.apps.map(({ id }) => this.fetchAppRoles(id)),
            ]);

          }).catch((err) => this.notifyError(err.message));
      }
    },

    mounted() {
      this.fetchApps();
    }
  };
</script>
<style scoped>
  .col-app {
    @apply pl-4 pr-2 w-1/3;
  }
  .col-roles {
    @apply w-2/3 flex-shrink-0;
    margin-left: auto;
  }
  .col-metarole {
    @apply px-2 w-1/2;
  }
  .col-approle {
    @apply pl-2 pr-4 w-1/2;
  }
  .row-template {
    background-color: var(--highlightColor100);
    margin: 0;
    @apply border border-blue-300;
  }
  .app-title:hover :deep(button) {
    @apply opacity-100;
  }
</style>
