<template>
  <Form
    :submit="handleFormSubmit"
    :initialValues="initialValues"
    @change="handleFormChange"
  >
      <div class="form-narrow">
        <label class="form-section-title">main information</label>

        <div class="form-row w-1/2">
          <TextField name="name" label="name" :validate="required" />
        </div>
        <div class="form-row">
          <TextField name="description" label="description" :validate="required" />
        </div>

        <bundle-meta-roles class="mt-8" :data="initialValues.metaroles" @update="updateMetaRoles"></bundle-meta-roles>

        <bundle-application-roles
          class="mt-8"
          :metaroles="metaRoles"
          :mapped-roles="formValues.mappedRoles"
          :initialApps="initialValues.apps"
          :initialMappedRoles="initialValues.mappedRoles"
          @update-apps="updateIncludedApps"
        ></bundle-application-roles>

        <ModalFooter :footer="footer" :tertiary="cancel" />
      </div>
  </Form>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import Form from "@/components/form/Form";
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import TextField from "@/components/form/TextField";
  import BundleMetaRoles from "@/views/auth/applications/bundles/BundleMetaRoles";
  import BundleApplicationRoles from "@/views/auth/applications/bundles/BundleApplicationRoles";
  import { ROLES } from '@/components/auth/constants';

  export default {
    name: "BundleForm",
    components: {
      ModalFooter,
      Form,
      TextField,
      BundleMetaRoles,
      BundleApplicationRoles,
    },
    mixins: [
      ModalNavigation,
      NotifyMixin,
      ValidatorMixin,
    ],
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      initialValues: {
        type: Object,
        default: () => ({})
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data(){
      return {
        metaRoles: [],
        includedApps: [],
        formValues: {},
        footer: {
          primaryButton: 'save',
          tertiaryButton: 'cancel'
        },
      }
    },
    methods: {
      handleFormChange(state) {
        this.formValues = state.values;
      },

      updateMetaRoles(payload) {
        this.metaRoles = payload;
      },

      updateIncludedApps(payload) {
        this.includedApps = payload;
      },

      handleFormSubmit({ mappedRoles, ...values }) {
        if (this.metaRoles.length === 0) {
          this.notifyError('Bundle should contain at least one metarole');
          return;
        }

        const apps = this.includedApps.map(a => a.id);
        const metaroles = this.metaRoles.map(({ id, ...metarole }) => {
          const roles = apps.map((appId) => ({
            appId,
            id: mappedRoles[id][appId] === ROLES.NO_ROLE ? null : mappedRoles[id][appId],
          }));
          const data = {
            ...metarole,
            roles,
          };
          if (id && !id.startsWith('fake')) {
            data['id'] = id;
          }
          return data;
        });

        this.onSubmit({
          ...values,
          apps,
          metaroles,
        });
      },

      cancel(e) {
        e.preventDefault();
        this.close();
      }
    },
  }
</script>

<style scoped>

</style>
