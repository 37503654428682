<template>
    <div class="border border-blue-300 bg-blue-50 p-6 text-blue-700 font-500 text-sm">
        <!-- @slot Content inside the block -->
        <slot />
    </div>
</template>

<script>

export default {
    name: 'InfoBlock',
};
</script>

